import React from 'react'
import Link from 'gatsby-link'

import {siteNav} from '../utils/sectionList';
import strings from '../utils/strings';
import siteInfo from '../../content/info.js';

import styles from "./header.module.less";

function adjustLinkTarget(target) {
  const primaryLang = siteInfo.languages[0]
  if (target === `/${primaryLang}/`) {
    return '/'
  } else {
    return target
  }
}

const NavItem = ({ lang, item }) => (
  <li>
    {
      item.href ?
        <a href={item.href}>
          <span>{item.title}</span>
        </a>
      :
        <Link
          to={adjustLinkTarget('/' + lang + (item.location || ('/' + item.id)))}
          exact={item.exact}
          isActive={item.location &&
            ((_, loc) => loc.pathname.startsWith(`/${lang}/${item.id}`))}
          activeClassName={styles.active}>
          <span>{item.title}</span>
        </Link>
    }
    {
      item.items && <ul className={styles.globalNavDropdown}>
        {
          item.items.map((subitem) => <li key={subitem.id}>
            {
              subitem.href ?
                <a href={subitem.href}>
                  {subitem.title}
                </a>
              :
                <Link
                  to={adjustLinkTarget('/' + lang + (subitem.location || `/${item.id}/${subitem.id}`))}
                  exact={subitem.exact}
                  activeClassName={styles.active}>
                  {subitem.title}
                </Link>
            }
          </li>)
        }
      </ul>
    }
  </li>
);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mini: false,
    };

    this.updateStyle = this.updateStyle.bind(this);
  }

  updateStyle() {
    const shouldUseMiniStyle = window.scrollY > 100;
    if (shouldUseMiniStyle !== this.state.mini) {
      this.setState({ mini: shouldUseMiniStyle });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateStyle);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateStyle);
  }

  render() {
    const { lang } = this.props;
    const { mini } = this.state;
    const { branding } = strings[lang];
    return (
      <header className={styles.header + (mini ? ' ' + styles.mini : '')}>
        <div>
          <h1 className={styles.branding}>
            {branding.main}<small>{branding.sub}</small>
          </h1>
          <nav className={styles.globalNav}>
            <ul>
              {
                siteNav[lang].map((item) =>
                  <NavItem item={item} lang={lang} key={item.id} />)
              }
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header
