import React from 'react'

import strings from '../utils/strings'
import siteInfo from '../../content/info'

import styles from "./footer.module.less"

const Footer = ({lang}) => (
  <footer className={styles.footer}>
    <div>
      <div className={styles.branding}>
        {strings[lang].footer.lines.map((line, i) => <div key={i}>{line}</div>)}
      </div>
      <nav>
        <ul className={styles.nav}>
          {strings[lang].footer.links.map(({caption, href}, i) =>
            <li key={i}><a href={href}>{caption}</a></li>)}
          <li>{
            strings[lang].footer.contact.replace('{}', siteInfo.emailAddress.replace('@', '[at]'))
          }</li>
        </ul>
      </nav>
    </div>
  </footer>
)

export default Footer
