import React from 'react'
import Helmet from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './index.less'
import styles from './index.module.less'

import siteInfo from '../utils/config';
import locales from '../utils/strings';

const Layout = ({ children, location, data }) => {
  const {languages} = siteInfo;

  // Detect the language of the current page
  let language = location && location.pathname.substr(1).split('/')[0];
  if (languages.indexOf(language) < 0) {
    language = languages[0];
  }

  const strings = locales[language].meta

  return (
    <div className={'lang-' + language}>
      <Helmet
        title={"ERTL — Embedded Real-Time Laboratory"}
        meta={[
          { name: 'description', content: strings.description },
          { name: 'keywords', content: strings.keywords },
        ]}
      />
      <Header lang={language} />
      <div className={styles.contentWrapper}>
        {children}
      </div>
      <Footer lang={language} />
    </div>
  );
};

export default Layout
